import { lazy } from 'react';

// project imports
import AuthGuard from 'utils/route-guard/AuthGuard';
import MainLayout from 'layout/MainLayout';
import NavMotion from 'layout/NavMotion';
import Loadable from 'ui-component/Loadable';

// login routing

//Pages

const UserView = Loadable(lazy(() => import('pages/dashboard/users/index')));

const Dashboard = Loadable(
  lazy(() => import('pages/dashboard/dashboard/index'))
);

const Clients = Loadable(lazy(() => import('pages/dashboard/client')));

const ClientAddEdit = Loadable(
  lazy(() => import('pages/dashboard/client/ClientAddEdit'))
);

const ClientView = Loadable(
  lazy(() => import('pages/dashboard/client/ClientView'))
);

const Pages = Loadable(lazy(() => import('pages/dashboard/pages')));

const PagesCreate = Loadable(
  lazy(() => import('pages/dashboard/pages/PageCreateEdit.page'))
);

const PagesView = Loadable(
  lazy(() => import('pages/dashboard/pages/PageView.page'))
);

const Emergencies = Loadable(lazy(() => import('pages/dashboard/emergencies')));
const GlobalActiveEmergencies = Loadable(lazy(() => import('pages/dashboard/global-active-emergencies')));

const EmergencyDetails = Loadable(
  lazy(() => import('pages/dashboard/emergencies/EmergencyDetails'))
);
const Reports = Loadable(
  lazy(() => import('pages/dashboard/reports'))
);

const UnderConstruction = Loadable(
  lazy(() => import('pages/errors/UnderConstruction.page'))
);

// const AlertGroupAddEdit = Loadable(
//   lazy(() => import('pages/dashboard/alertGroups/AlertGroupAddEdit'))
// );

const EntityAdminAddEdit = Loadable(
  lazy(() => import('pages/dashboard/entity/entity-admins/AddEntityAdmin'))
);

const EntitySupportStaffAddEdit = Loadable(
  lazy(() =>
    import('pages/dashboard/entity/entity-support-staff/AddEntityAdmin')
  )
);

const MonitoringCentreAddEdit = Loadable(
  lazy(() => import('pages/dashboard/monitoringCenter/MonitoringCentre'))
);

const SuperAdminAddEdit = Loadable(
  lazy(() => import('pages/dashboard/superAdmin/superAdmin'))
);

const Alerts = Loadable(lazy(() => import('pages/dashboard/alerts')));

const AlertGroups = Loadable(lazy(() => import('pages/dashboard/alertGroups')));

const AlertGroupsAddEdit = Loadable(
  lazy(() => import('pages/dashboard/alertGroups/AlertGroupAddEdit'))
);

const FieldOperativeAddEdit = Loadable(
  lazy(() => import('pages/dashboard/fieldOperatives/FieldOperative'))
);

const Settings = Loadable(
  lazy(() => import('pages/dashboard/settings/Settings'))
);

const EntityList = Loadable(lazy(() => import('pages/dashboard/entity/index')));

const EntityCreateOrEdit = Loadable(
  lazy(() => import('pages/dashboard/entity/AddEntity'))
);

const Roles = Loadable(lazy(() => import('pages/dashboard/roles/Roles')));

const RoleList = Loadable(lazy(() => import('pages/dashboard/roles/index')));
const SupportStaffAddEdit = Loadable(
  lazy(() => import('pages/dashboard/support-staff/SupportStaff'))
);

const ArchivedEmergenciesView = Loadable(
  lazy(() => import('pages/dashboard/archived-emergencies/index'))
);

const ProfileDetail = Loadable(
  lazy(() => import('layout/MainLayout/Header/ProfileSection/ProfileDetail'))
);

const PasswordChange = Loadable(
  lazy(() => import('layout/MainLayout/Header/ProfileSection/PasswordChange'))
);


const GlobalReports = Loadable(
  lazy(() => import('pages/dashboard/global-reports'))
);
// ==============================|| DASHBOARD ROUTING ||============================== //

const DashboardRoutes = {
  path: '/',
  element: (
    <NavMotion>
      <AuthGuard>
        <MainLayout />
      </AuthGuard>
    </NavMotion>
  ),
  children: [
    {
      path: '/dashboard',
      exact: true,
      element: <Dashboard />,
    },
    {
      path: '/dashboard/:entityId',
      exact: true,
      element: <Dashboard />,
    },
    {
      path: '/pages',
      exact: true,
      element: <Pages />,
    },
    {
      path: '/pages/create',
      exact: true,
      element: <PagesCreate />,
    },
    {
      path: '/page/:pageId/edit',
      exact: true,
      element: <PagesCreate />,
    },
    {
      path: '/page/:pageId/view',
      exact: true,
      element: <PagesView />,
    },
    {
      path: '/clients',
      exact: true,
      element: <Clients />,
    },
    {
      path: 'roles',
      exact: true,
      element: <RoleList />,
    },
    {
      path: 'roles/:userRole',
      exact: true,
      element: <Roles />,
    },
    {
      path: '/reports',
      exact: true,
      element: <Reports />,
    },
    {
      path: '/emergencies',
      exact: true,
      element: <Emergencies />,
    },
    {
      path: '/emergency/:emergencyId',
      exact: true,
      element: <EmergencyDetails />,
    },
    {
      path: '/global-active-emergencies',
      exact: true,
      element: <GlobalActiveEmergencies />,
    },
    {
      path: '/clients/add',
      exact: true,
      element: <ClientAddEdit />,
    },
    {
      path: '/clients/:id/view',
      exact: true,
      element: <ClientView />,
    },
    {
      path: '/clients/:id/edit',
      exact: true,
      element: <ClientAddEdit />,
    },
    {
      path: '/entities',
      exact: true,
      element: <EntityList />,
    },
    {
      path: '/entities/add',
      exact: true,
      element: <EntityCreateOrEdit />,
    },
    {
      path: '/entities/update/:entityId',
      exact: true,
      element: <EntityCreateOrEdit />,
    },
    {
      path: 'alerts',
      exact: true,
      element: <Alerts />,
    },
    {
      path: '/users',
      exact: true,
      element: <UserView />,
    },
    {
      path: '/superAdmin/add',
      exact: true,
      element: <SuperAdminAddEdit />,
    },
    {
      path: '/superAdmin/update/:superAdminId',
      exact: true,
      element: <SuperAdminAddEdit />,
    },
    {
      path: 'alert-groups',
      exact: true,
      element: <AlertGroups />,
    },
    {
      path: 'monitoringCentre/add',
      exact: true,
      element: <MonitoringCentreAddEdit />,
    },
    {
      path: 'monitoringCentre/update/:monitoringCentreId',
      exact: true,
      element: <MonitoringCentreAddEdit />,
    },
    {
      path: '/under-construction',
      exact: true,
      element: <UnderConstruction />,
    },
    {
      path: 'alert-groups',
      exact: true,
      element: <AlertGroups />,
    },
    {
      path: '/entity/admins/add',
      exact: true,
      element: <EntityAdminAddEdit />,
    },
    {
      path: '/entity/admins/add/:entityId',
      exact: true,
      element: <EntityAdminAddEdit />,
    },
    {
      path: 'entity/admins/update/:entityAdminId',
      exact: true,
      element: <EntityAdminAddEdit />,
    },
    {
      path: '/entity/supportStaff/add',
      exact: true,
      element: <EntitySupportStaffAddEdit />,
    },
    {
      path: 'entity/supportStaff/update/:entityAdminId',
      exact: true,
      element: <EntitySupportStaffAddEdit />,
    },
    {
      path: '/supportStaff/add',
      exact: true,
      element: <SupportStaffAddEdit />,
    },
    {
      path: 'supportStaff/update/:supportStaffId',
      exact: true,
      element: <SupportStaffAddEdit />,
    },
    {
      path: 'alert-groups/:companyId/add',
      exact: true,
      element: <AlertGroupsAddEdit />,
    },
    {
      path: '/field-operative/add',
      exact: true,
      element: <FieldOperativeAddEdit />,
    },
    {
      path: '/field-operative/update/:fieldOperativeId',
      exact: true,
      element: <FieldOperativeAddEdit />,
    },
    {
      path: 'alert-groups/:id/edit',
      exact: true,
      element: <AlertGroupsAddEdit />,
    },
    {
      path: 'settings',
      exact: true,
      element: <Settings />,
    },
    {
      path: 'archived-emergencies',
      exact: true,
      element: <ArchivedEmergenciesView />,
    },
    {
      path: 'user/account-profile/profile',
      exact: true,
      element: <ProfileDetail />,
    },
    {
      path: 'user/account/change-password',
      exact: true,
      element: <PasswordChange />,
    },
    {
      path: '/global-reports',
      exact: true,
      element: <GlobalReports />,
    },
  ],
};

export default DashboardRoutes;
