import React, { useEffect, useState } from 'react';
import { TextField, Autocomplete } from '@mui/material';
import useAuth from 'hooks/useAuth';
import { updateRecord } from 'services/AxiosAdmin';
import { useSelector } from 'store';
import { useDispatch } from 'store';
import { getDefaultEntityId } from 'store/slices/user';

const EntitySelect = () => {
  const { user, updateProfile } = useAuth();
  const [entities, setEntities] = useState([]);
  const [defaultValue, setDefaultValue] = useState({});
  const { defaultEntityId } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const handleChange = async (entity) => {
    const body = {
      ...user,
      defaultEntityId: entity.id,
    };
    return updateRecord('/admin/auth/me/update', body).then(async (res) => {
      if (res.status === 200) {
        updateProfile({
          ...user,
          defaultEntityId: res.data.data.defaultEntityId,
        });
        dispatch(getDefaultEntityId());
      }
    });
  };
  useEffect(() => {
    if (window.sessionStorage.getItem('entityId')) {
      setDefaultValue({
        id: window.sessionStorage.getItem('entityId'),
        label: window.sessionStorage.getItem('entityName'),
      });
    } else {
      if (user?.entities) {
        let arrayObj = user.entities
          .filter((data) => data.isEntityActive === true)
          .map((item) => {
            return {
              label: item.name,
              id: item._id,
            };
          });

        setEntities(arrayObj);
      }
    }
  }, [user.entities]);
  useEffect(() => {
    if (!window.sessionStorage.getItem('entityId')) {
      if (entities && entities.length > 0) {
        let falseDefaultEntity = {};
        if (defaultEntityId) {
          let data = entities.find(({ id }) => id === defaultEntityId);
          if (data) {
            falseDefaultEntity = data;
          }
        }
        if (falseDefaultEntity?.id) {
          setDefaultValue(falseDefaultEntity);
        } else {
          setDefaultValue(entities[0]);
        }
      }
    }
  }, [entities, defaultEntityId]);
  return (
    <>
      {defaultValue?.label && (
        <Autocomplete
          disablePortal
          id='combo-box-demo'
          name='organization'
          onChange={(_, value) => {
            handleChange(value);
          }}
          className='default-entity-input'
          value={defaultValue}
          disabled={
            window.sessionStorage?.roles === 'entityPrincipalAdmin'
              ? true
              : false
          }
          options={entities ? entities : [defaultValue]}
          disableClearable
          noOptionsText='No Entities Available'
          isOptionEqualToValue={(option, value) => option.id === value.id}
          getOptionLabel={(option) => option.label}
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                variant='standard'
                InputProps={{
                  ...params.InputProps,
                }}
              />
            );
          }}
        />
      )}
    </>
  );
};

export default EntitySelect;
