import axios from 'axios';
import { clearAccessToken, getAccessToken } from '../services/StorageAdmin';

const API_URI = process.env.REACT_APP_API_URL;
const API = axios.create({
  // withCredentials: true,
  baseURL: API_URI,
  timeout: 20000,
  headers: { Pragma: 'no-cache', 'Cache-Control': 'no-cache, no-store' },
});

API.interceptors.request.use(
  (config) => {
    config.headers.Authorization = 'Bearer ' + getAccessToken();
    return config;
  },
  (e) => Promise.reject(e)
);

const GENERAL = {
  status: '',
  message: '',
  data: [],
};

//Response Interceptor
API.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (
      error.response.status === 401 &&
      error?.response?.config?.url !== '/admin/auth/me'
    ) {
      clearAccessToken();
      window.location.href = '/login';
    }
    return Promise.reject(error);
  }
);

const postRecord = async (relativeUri, data) => {
  try {
    let response = await API.post(relativeUri, data)
      .then((response) => {
        GENERAL.status = response.status;
        GENERAL.message = response.data.message;
        GENERAL.data = response.data.data;
        return response;
      })
      .catch((e) => {
        GENERAL.status = e.response.status;
        GENERAL.message = e.response.data.message;
        GENERAL.data = e.response.data.data;
        return GENERAL;
      });
    return response;
  } catch (e) {
    return GENERAL;
  }
};

const updateRecord = async (relativeUri, data) => {
  try {
    let response = await API.patch(relativeUri, data)
      .then((response) => {
        GENERAL.status = response.status;
        GENERAL.message = response.data.message;
        GENERAL.data = response.data.data;
        return response;
      })
      .catch((e) => {
        GENERAL.status = e.response.status;
        GENERAL.message = e.response.data.message;
        GENERAL.data = e.response.data.data;

        return GENERAL;
      });
    return response;
  } catch (e) {
    return GENERAL;
  }
};

const fetchRecord = async (relativeUri, queryParams) => {
  try {
    if (queryParams) {
      let str = '';
      for (var key in queryParams) {
        if (str !== '' && queryParams[key]) {
          str += '&';
        }
        str += queryParams[key]
          ? key + '=' + encodeURIComponent(queryParams[key])
          : '';
      }
      relativeUri = relativeUri + '?' + str;
    }
    let response = await API.get(relativeUri)
      .then((response) => {
        GENERAL.status = response.status;
        GENERAL.message = response.data.message;
        GENERAL.data = response.data.data;
        return response;
      })
      .catch((e) => {
        GENERAL.status = e.response.status;
        GENERAL.message = e.response.data.message;
        GENERAL.data = e.response.data.data;

        return GENERAL;
      });

    return response;
  } catch (e) {
    return false;
  }
};
const fetchRecordWithError = async (relativeUri, queryParams) => {
  try {
    if (queryParams) {
      let str = '';
      for (var key in queryParams) {
        if (str !== '' && queryParams[key]) {
          str += '&';
        }
        str += queryParams[key]
          ? key + '=' + encodeURIComponent(queryParams[key])
          : '';
      }
      relativeUri = relativeUri + '?' + str;
    }
    let response = await API.get(relativeUri)
      .then((response) => {
        GENERAL.status = response.status;
        GENERAL.message = response.data.message;
        GENERAL.data = response.data.data;
        return response;
      })
      .catch((e) => {
        GENERAL.status = e?.response?.status || e.status;
        GENERAL.message = e?.response?.data?.message || e.message;
        GENERAL.data = e?.response?.data?.data || [];

        throw GENERAL;
      });

    return response;
  } catch (e) {
    throw e;
  }
};

const deleteRecord = async (relativeUri, params) => {
  try {
    let response = await API.delete(relativeUri)
      .then((response) => {
        GENERAL.status = response.status;
        GENERAL.message = response.data.message;
        GENERAL.data = response.data.data;
        return response;
      })
      .catch((e) => {
        GENERAL.status = e.response.status;
        GENERAL.message = e.response.data.message;
        GENERAL.data = e.response.data.data;

        return GENERAL;
      });

    return response;
  } catch (e) {
    return false;
  }
};
const deleteRecordWithError = async (relativeUri, params) => {
  try {
    let response = await API.delete(relativeUri)
      .then((response) => {
        GENERAL.status = response.status;
        GENERAL.message = response.data.message;
        GENERAL.data = response.data.data;
        return response;
      })
      .catch((e) => {
        throw e;
      });

    return response;
  } catch (e) {
    return false;
  }
};

export {
  API,
  postRecord,
  updateRecord,
  fetchRecord,
  deleteRecord,
  fetchRecordWithError,
  deleteRecordWithError,
};
