import { lazy } from 'react';

// project imports
import GuestGuard from 'utils/route-guard/GuestGuard';
import MinimalLayout from 'layout/MinimalLayout';
import NavMotion from 'layout/NavMotion';
import Loadable from 'ui-component/Loadable';

// login routing
const AuthLogin = Loadable(
  lazy(() => import('pages/authentication/Login.page'))
);
const AuthForgotPassword = Loadable(
  lazy(() => import('pages/authentication/ForgetPassword.page'))
);

const AuthResetPassword = Loadable(
  lazy(() => import('pages/authentication/ResetPassword.page'))
);

const PrivacyPolicy = Loadable(
  lazy(() => import('pages/authentication/PrivacyPolicy.page'))
);

// ==============================|| AUTH ROUTING ||============================== //

const LoginRoutes = {
  path: '/',
  element: (
    <NavMotion>
      <GuestGuard>
        <MinimalLayout />
      </GuestGuard>
    </NavMotion>
  ),
  children: [
    {
      path: '/login',
      exact: true,
      element: <AuthLogin />,
    },
    {
      path: '/forgot',
      exact: true,
      element: <AuthForgotPassword />,
    },
    {
      path: '/reset-password',
      exact: true,
      element: <AuthResetPassword />,
    },
    {
      path: '/privacy-policy-lite',
      exact: true,
      element: <PrivacyPolicy />,
    },
  ],
};

export default LoginRoutes;
