import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
  Box,
  Chip,
  ClickAwayListener,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Popper,
  Stack,
  Typography,
} from '@mui/material';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import Transitions from 'ui-component/extended/Transitions';
import useAuth from 'hooks/useAuth';
import AvatarFirstLetterIcon from 'Commons/AvatarFirstLetterIcon';

// assets
import { IconLogout, IconSettings } from '@tabler/icons';
import useConfig from 'hooks/useConfig';
import { roles } from 'store/constant';

// ==============================|| PROFILE MENU ||============================== //

const ProfileSection = () => {
  const theme = useTheme();
  const { borderRadius } = useConfig();
  const navigate = useNavigate();

  // const [notification, setNotification] = useState(false);
  const { logout, user } = useAuth();
  const [open, setOpen] = useState(false);

  /**
   * anchorRef is used on different components and specifying one type leads to other components throwing an error
   * */
  const anchorRef = useRef(null);
  const handleLogout = async () => {
    try {
      if (sessionStorage.getItem('roles')) {
        window.close();
      } else {
        await logout();
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <>
      <Chip
        sx={{
          height: '48px',
          alignItems: 'center',
          borderRadius: '27px',
          transition: 'all .2s ease-in-out',
          borderColor:
            theme.palette.mode === 'dark'
              ? theme.palette.dark.main
              : theme.palette.primary.main,
          backgroundColor:
            theme.palette.mode === 'dark'
              ? theme.palette.dark.main
              : theme.palette.primary.main,
          '&[aria-controls="menu-list-grow"], &:hover': {
            borderColor: theme.palette.primary.main,
            background: `${theme.palette.primary.main}!important`,
            color: theme.palette.primary.light,
            '& svg': {
              stroke: theme.palette.primary.light,
            },
          },
          '& .MuiChip-label': {
            lineHeight: 0,
          },
        }}
        label={
          <AvatarFirstLetterIcon
            srcImage={user?.profilePhoto || ''}
            name={user?.fullName || ''}
          />
        }
        // label={
        //   <IconSettings
        //     stroke={1.5}
        //     size='1.5rem'
        //     color={theme.palette.primary.main}
        //   />
        // }
        variant='outlined'
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup='true'
        onClick={handleToggle}
        color='primary'
      />
      <Popper
        placement='bottom'
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 14],
              },
            },
          ],
        }}
      >
        {({ TransitionProps }) => (
          <ClickAwayListener
            onClickAway={(e) => {
              handleClose(e);
            }}
          >
            <Transitions in={open} {...TransitionProps}>
              <Paper>
                {open && (
                  <MainCard
                    border={false}
                    elevation={16}
                    content={false}
                    boxShadow
                    shadow={theme.shadows[16]}
                  >
                    <Box sx={{ p: 2, pb: 0 }}>
                      <Stack>
                        <Stack
                          direction='row'
                          spacing={0.5}
                          alignItems='center'
                        >
                          <Typography variant='h4'>
                            Good{' '}
                            {`${
                              new Date().getHours() < 12 ? 'Morning' : 'Evening'
                            }, ${
                              user?.fullName.split(' ').length > 0
                                ? user?.fullName.split(' ')[0]
                                : ''
                            }`}
                          </Typography>
                          <Typography
                            component='span'
                            variant='h4'
                            sx={{ fontWeight: 400 }}
                          >
                            {user?.name}
                          </Typography>
                        </Stack>
                        <Typography variant='subtitle2' color='secondary'>
                          {user?.role && roles[user?.role]}
                        </Typography>
                      </Stack>
                    </Box>
                    <PerfectScrollbar
                      style={{
                        height: '100%',
                        maxHeight: 'calc(100vh - 250px)',
                        overflowX: 'hidden',
                      }}
                    >
                      <Box sx={{ p: 2, pt: 0 }}>
                        <List
                          component='nav'
                          sx={{
                            width: '100%',
                            maxWidth: 350,
                            minWidth: 300,
                            backgroundColor: theme.palette.background.paper,
                            borderRadius: '10px',
                            [theme.breakpoints.down('md')]: {
                              minWidth: '100%',
                            },
                            '& .MuiListItemButton-root': {
                              mt: 0.5,
                            },
                          }}
                        >
                          <ListItemButton
                            sx={{ borderRadius: `${borderRadius}px` }}
                            onClick={(e) => {
                              navigate('/user/account-profile/profile', {
                                state: { title: 'Profile Settings' },
                              });
                              handleClose(e);
                            }}
                          >
                            <ListItemIcon>
                              <IconSettings stroke={1.5} size='1.3rem' />
                            </ListItemIcon>
                            <ListItemText
                              primary={
                                <Typography variant='body1'>
                                  Profile Settings
                                </Typography>
                              }
                            />
                          </ListItemButton>

                          <ListItemButton
                            sx={{ borderRadius: `${borderRadius}px` }}
                            onClick={(e) => {
                              navigate('/user/account/change-password', {
                                state: { title: 'Change Password Settings' },
                              });
                              handleClose(e);
                            }}
                          >
                            <ListItemIcon>
                              <IconSettings stroke={1.5} size='1.3rem' />
                            </ListItemIcon>
                            <ListItemText
                              primary={
                                <Typography variant='body1'>
                                  Change Password Settings
                                </Typography>
                              }
                            />
                          </ListItemButton>

                          <ListItemButton
                            sx={{ borderRadius: `${borderRadius}px` }}
                            onClick={handleLogout}
                          >
                            <ListItemIcon>
                              <IconLogout stroke={1.5} size='1.3rem' />
                            </ListItemIcon>
                            <ListItemText
                              primary={
                                <Typography variant='body1'>Logout</Typography>
                              }
                            />
                          </ListItemButton>
                        </List>
                      </Box>
                    </PerfectScrollbar>
                  </MainCard>
                )}
              </Paper>
            </Transitions>
          </ClickAwayListener>
        )}
      </Popper>
    </>
  );
};

export default ProfileSection;
