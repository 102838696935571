// assets
import {
  IconHome,
  IconWorld,
  IconUser,
  IconAlertTriangle,
  IconSpeakerphone,
  IconUsers,
  IconList,
  IconShieldLock,
  IconReport
} from '@tabler/icons';
import { ADMIN_ROLE } from 'store/constant';

// constant
const icons = {
  IconHome,
  IconWorld,
  IconUser,
  IconAlertTriangle,
  IconSpeakerphone,
  IconUsers,
  IconList,
  IconShieldLock,
  IconReport
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const pages = {
  id: 'menu',
  title: '',
  caption: '',
  type: 'group',
  children: [
    {
      id: 'dashboard',
      title: 'Dashboard',
      type: 'item',
      icon: icons.IconHome,
      url: '/dashboard',
      roles: [
        ADMIN_ROLE.SPG_SUPER_ADMIN,
        ADMIN_ROLE.SPG_SUPPORT_STAFF,
        ADMIN_ROLE.ENTITY_PRINCIPAL_ADMIN,
        ADMIN_ROLE.MONITORING_CENTRE,
        ADMIN_ROLE.ENTITY_SUPPORT_STAFF,
      ],
    },
    {
      id: 'entity-management',
      title: 'Entity Management',
      type: 'item',
      icon: icons.IconWorld,
      url: '/entities',
      roles: [ADMIN_ROLE.SPG_SUPER_ADMIN, ADMIN_ROLE.SPG_SUPPORT_STAFF],
    },
    {
      id: 'user-management',
      title: 'User Management',
      type: 'item',
      icon: icons.IconUser,
      url: '/users#super-admin',
      roles: [
        ADMIN_ROLE.SPG_SUPER_ADMIN,
        ADMIN_ROLE.SPG_SUPPORT_STAFF,
        ADMIN_ROLE.ENTITY_PRINCIPAL_ADMIN,
      ],
    },
    {
      id: 'pages',
      title: 'Pages',
      type: 'item',
      icon: icons.IconUser,
      url: '/pages',
      roles: [ADMIN_ROLE.SPG_SUPER_ADMIN, ADMIN_ROLE.SPG_SUPPORT_STAFF],
    },
    {
      id: 'global-reports',
      title: 'Global Report Management',
      type: 'item',
      icon: icons.IconReport,
      url: '/global-reports#clients',
      roles: [ADMIN_ROLE.SPG_SUPER_ADMIN],
    },
    {
      id: 'emergencies',
      title: 'Emergencies',
      type: 'item',
      icon: icons.IconShieldLock,
      url: '/emergencies#activeEmergencies',
      roles: [
        ADMIN_ROLE.SPG_SUPPORT_STAFF,
        ADMIN_ROLE.ENTITY_PRINCIPAL_ADMIN,
        ADMIN_ROLE.ENTITY_SUPPORT_STAFF,
        ADMIN_ROLE.MONITORING_CENTRE,
      ],
    },
    {
      id: 'global-active-emergencies',
      title: 'Global Active Emergencies',
      type: 'item',
      icon: icons.IconShieldLock,
      url: '/global-active-emergencies',
      roles: [
        ADMIN_ROLE.SPG_SUPPORT_STAFF,
        ADMIN_ROLE.ENTITY_PRINCIPAL_ADMIN,
        ADMIN_ROLE.MONITORING_CENTRE
      ],
    },
    {
      id: 'emergencies',
      title: 'Active emergencies',
      type: 'item',
      icon: icons.IconShieldLock,
      url: '/emergency',
      roles: [],
    },
    {
      id: 'alerts',
      title: 'Alerts',
      type: 'item',
      icon: icons.IconAlertTriangle,
      url: '/alerts#users',
      roles: [
        ADMIN_ROLE.SPG_SUPPORT_STAFF,
        ADMIN_ROLE.ENTITY_PRINCIPAL_ADMIN,
        ADMIN_ROLE.ENTITY_SUPPORT_STAFF,
      ],
    },
    {
      id: 'alert-groups',
      title: 'Alert Groups',
      type: 'item',
      icon: icons.IconSpeakerphone,
      url: '/alert-groups',
      roles: [
        ADMIN_ROLE.SPG_SUPPORT_STAFF,
        ADMIN_ROLE.ENTITY_PRINCIPAL_ADMIN,
        ADMIN_ROLE.ENTITY_SUPPORT_STAFF,
      ],
    },
    {
      id: 'clients',
      title: 'Clients',
      type: 'item',
      icon: icons.IconUsers,
      url: '/clients',
      roles: [
        ADMIN_ROLE.SPG_SUPPORT_STAFF,
        ADMIN_ROLE.ENTITY_PRINCIPAL_ADMIN,
        ADMIN_ROLE.ENTITY_SUPPORT_STAFF,
      ],
    },
    {
      id: 'reports',
      title: 'Report',
      type: 'item',
      icon: icons.IconReport,
      url: '/reports',
      roles: [
        ADMIN_ROLE.SPG_SUPPORT_STAFF,
        ADMIN_ROLE.ENTITY_PRINCIPAL_ADMIN,
        ADMIN_ROLE.MONITORING_CENTRE
      ],
    },
    {
      id: 'archived-emergencies',
      title: 'Archived Emergencies',
      type: 'item',
      icon: icons.IconList,
      url: '/archived-emergencies',
      roles: [
        ADMIN_ROLE.SPG_SUPPORT_STAFF,
        ADMIN_ROLE.MONITORING_CENTRE,
        ADMIN_ROLE.ENTITY_PRINCIPAL_ADMIN,
      ],
    },
    {
      id: 'roles',
      title: 'Roles',
      type: 'item',
      icon: icons.IconList,
      url: '/roles',
      roles: [
        ADMIN_ROLE.ENTITY_PRINCIPAL_ADMIN,
        ADMIN_ROLE.ENTITY_SUPPORT_STAFF,
        ADMIN_ROLE.SPG_SUPER_ADMIN,
        ADMIN_ROLE.SPG_SUPPORT_STAFF,
        ADMIN_ROLE.MONITORING_CENTRE,
      ],
    },
    {
      id: 'settings',
      title: 'Settings',
      type: 'item',
      icon: icons.IconShieldLock,
      url: '/settings',
      roles: [
        ADMIN_ROLE.SPG_SUPER_ADMIN,
        ADMIN_ROLE.SPG_SUPPORT_STAFF,
        ADMIN_ROLE.ENTITY_PRINCIPAL_ADMIN,
        ADMIN_ROLE.ENTITY_SUPPORT_STAFF,
      ],
    },
  ],
};

export default pages;
