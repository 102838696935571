import usePermission from 'hooks/usePermission';

const RoleGuard = ({ children, permissionOn, permissionOnType = '' }) => {
  const hasPermission = usePermission(permissionOn, permissionOnType);

  if (hasPermission) {
    return <>{children}</>;
  }

  return <></>;
};

export default RoleGuard;
