/*
 * date must be on UTC Time
 */
// import moment from 'moment';
// require('moment-timezone');
import moment from 'moment-timezone';

const timeHelper = (date) => {
  if (date === null) return null;
  let dateNow = new Date(date).toLocaleTimeString();
  return dateNow;
};

const HourMinuteHelper = (date) => {
  if (date === null) return null;
  let dateNow = new Date(date);
  return moment(dateNow).format('LT');
};

const HourMinuteHelperWithTimeZone = (date, timeZone = 'Etc/GMT') => {
  if (date === null) return null;
  const dateNow = new Date(date);
  const localDate = moment(dateNow);
  return localDate.tz(timeZone).format('hh:mm:ss a (z)');
};

const dateHelper = (date) => {
  if (date === null) return null;
  let dateNow = new Date(date);
  return moment(dateNow).format('l');
};

// const dateHelperWithTime

const monthHelper = (date) => {
  if (date === null) return null;
  let dateNow = new Date(date);
  return moment(dateNow).format('ll');
};

const chatDateTimeHelper = (date) => {
  if (date === null) return null;
  let dateNow = new Date(date);
  return moment(dateNow).startOf('second').fromNow();
};

const alertDateTimeHelper = (date) => {
  if (date === null) return null;
  let dateNow = new Date(date);
  let moment1 = moment(dateNow);
  let moment2 = moment(new Date());
  if (moment1.isSame(moment2, 'day')) {
    return moment(dateNow).startOf('second').fromNow() + ' - Today';
  } else {
    return moment(dateNow).format('h:mma - LL');
  }
};

const getCurrentFormattedDateAndTime = () => {
  const d = new Date();
  const pad = (val) => val.toString().padStart(2, '0');

  return `${d.getFullYear()}-${pad(d.getMonth() + 1)}-${pad(d.getDate())} ${pad(
    d.getHours()
  )}:${pad(d.getMinutes())}`;
};

export {
  timeHelper,
  dateHelper,
  monthHelper,
  HourMinuteHelper,
  chatDateTimeHelper,
  HourMinuteHelperWithTimeZone,
  alertDateTimeHelper,
  getCurrentFormattedDateAndTime,
};
