import { uploadToS3 } from 'services/S3BucketService';

export const dataUrlToFile = (url, fileName, fileType) => {
  const [data] = url.split(',');

  var n = data.length;

  const arr = new Uint8Array(n);

  while (n--) {
    arr[n] = data.charCodeAt(n);
  }

  const blob = new Blob([arr], { type: fileType });
  return new File([blob], fileName, { type: fileType });
};

export const fileToBase64 = async (file) => {
  let reader = new FileReader();
  reader.readAsDataURL(file);
  return reader;
};

export async function dataUrlToFileAsync(dataUrl, fileName, type) {
  const res = await fetch(dataUrl);
  const blob = await res.blob();
  return new File([blob], fileName, { type: type });
}

export function encodeAwsImageUrl(url) {
  if (url === undefined) return;
  let imageSrcUrlEncoded = url;
  let baseImageUrl = `https://${process.env.REACT_APP_AWS_BUCKET}.s3.amazonaws.com/admin`;
  let replacedImage = imageSrcUrlEncoded.replace(baseImageUrl, '');
  imageSrcUrlEncoded = encodeURIComponent(replacedImage);
  imageSrcUrlEncoded = baseImageUrl + imageSrcUrlEncoded;
  return imageSrcUrlEncoded;
}

export const uploadToS3OnChangeFile = async (
  file,
  name,
  phone,
  dir,
  subDirectory
) => {
  let fileName = '';
  fileName = name ? name?.replace(/\s/g, '') : '';
  fileName = phone ? fileName + phone.replace(/\s/g, '') : '';
  if (fileName)
    fileName += Date.now().toString() + '.' + file.type.split('/')[1];
  else fileName = Date.now().toString() + file.name.replace(/\s/g, '');
  fileName = fileName.replace(/\s/g, '');

  let savedImage = '';
  try {
    const newFile = new File([file], fileName, { type: file.type });
    const response = await uploadToS3(newFile, getDirectory(dir, subDirectory));
    savedImage = response.Key;
  } catch (e) {
    savedImage = '';
  }
  return savedImage;
};

const getDirectory = (directory = '', subDirectory = '', key = '') => {
  return directory + subDirectory + key;
};
