const accessTokenIdentifierKey = 'serviceToken';
const adminDetailsIdentifierKey = 'adminDetails';

const setAccessToken = (token) => {
  return localStorage.setItem(accessTokenIdentifierKey, token);
};

const getAccessToken = () => {
  return localStorage.getItem(accessTokenIdentifierKey);
};

const clearAccessToken = () => {
  return localStorage.removeItem(accessTokenIdentifierKey);
};

const setAdminDetails = (user) => {
  return localStorage.setItem(adminDetailsIdentifierKey, JSON.stringify(user));
};

const getAdminDetails = () => {
  return JSON.parse(localStorage.getItem(adminDetailsIdentifierKey));
};

const clearAdminDetails = () => {
  return localStorage.removeItem(adminDetailsIdentifierKey);
};

// const adminDetails = ()
export {
  setAccessToken,
  getAccessToken,
  clearAccessToken,
  setAdminDetails,
  getAdminDetails,
  clearAdminDetails,
};
