import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import { List, Typography } from '@mui/material';

// project imports
import NavItem from '../NavItem';
import NavCollapse from '../NavCollapse';
import useAuth from 'hooks/useAuth';

// ==============================|| SIDEBAR MENU LIST GROUP ||============================== //

const NavGroup = ({ item }) => {
  const theme = useTheme();
  const { user } = useAuth();

  if (window.sessionStorage.getItem('roles')) {
    user.role = 'entityPrincipalAdmin';
  }

  // menu list collapse & items
  const items = item.children?.map((menu) => {
    switch (menu.type) {
      case 'collapse':
        return <NavCollapse key={menu.id} menu={menu} level={1} />;
      case 'item':
        if (menu?.roles?.includes(user?.role)) {
          return <NavItem key={menu.id} item={menu} level={1} />;
        } else {
          return null;
        }
      default:
        return (
          <Typography key={menu.id} variant='h6' color='error' align='center'>
            Menu Items Error
          </Typography>
        );
    }
  });

  return (
    <>
      <List
        sx={{ paddingTop: '0', marginTop: '-10px' }}
        subheader={
          item.title && (
            <Typography
              variant='caption'
              sx={{ ...theme.typography.menuCaption }}
              display='block'
              gutterBottom
            >
              {item.title}
              {item.caption && (
                <Typography
                  variant='caption'
                  sx={{ ...theme.typography.subMenuCaption }}
                  display='block'
                  gutterBottom
                >
                  {item.caption}
                </Typography>
              )}
            </Typography>
          )
        }
      >
        {items}
      </List>

      {/* group divider */}
      {/* <Divider sx={{ mt: 0.25, mb: 1.25 }} /> */}
    </>
  );
};

NavGroup.propTypes = {
  item: PropTypes.object,
};

export default NavGroup;
