import { lazy } from 'react';
import { useRoutes } from 'react-router-dom';

// routes
import LoginRoutes from './LoginRoutes';
import ErrorRoutes from './ErrorRoutes';
import DashboardRoutes from './DashBoardRoutes';
import PublicRoutes from './PublicRoutes';
import Loadable from 'ui-component/Loadable';

const PagesLanding = Loadable(lazy(() => import('pages')));
const NotFound = Loadable(lazy(() => import('pages/errors/Error.page')));

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  return useRoutes([
    { path: '/', element: <PagesLanding /> },
    LoginRoutes,
    DashboardRoutes,
    PublicRoutes,
    ErrorRoutes,
    { path: '*', element: <NotFound /> },
  ]);
}
