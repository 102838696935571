import { lazy } from 'react';
import MinimalLayout from 'layout/MinimalLayout';
import Loadable from 'ui-component/Loadable';

const FollowMeMapComponent = Loadable(
  lazy(() => import('pages/public/followMeMap'))
);

// ==============================|| PUBLIC ROUTING ||============================== //

const ErrorRoutes = {
  path: '/',
  element: <MinimalLayout />,
  children: [
    {
      path: '/follow-me-map/:id',
      exact: true,
      element: <FollowMeMapComponent />,
    },
  ],
};

export default ErrorRoutes;
