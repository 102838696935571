import { lazy } from 'react';
// project imports
import MinimalLayout from 'layout/MinimalLayout';
import Loadable from 'ui-component/Loadable';

// login routing
const CommingSoon = Loadable(
  lazy(() => import('pages/errors/CommingSoon.page'))
);
const ErrorPage = Loadable(lazy(() => import('pages/errors/Error.page')));

// ==============================|| ERROR ROUTING ||============================== //

const ErrorRoutes = {
  path: '/',
  element: <MinimalLayout />,
  children: [
    {
      path: '/comming-soon',
      exact: true,
      element: <CommingSoon />,
    },
    {
      path: '/not-found',
      exact: true,
      element: <ErrorPage />,
    },
  ],
};

export default ErrorRoutes;
