// theme constant
export const gridSpacing = 3;
export const drawerWidth = 260;
export const appDrawerWidth = 320;
export const Mode = {
  ADD: 'Add',
  VIEW: 'View',
  EDIT: 'Update',
};
export const roles = {
  entityPrincipalAdmin: 'Entity Principal Admin',
  spgSuperAdmin: 'Super Admin',
  spgSupportStaff: 'Support Staff',
  entitySupportStaff: 'Entity Support Staff',
  monitoringCentre: 'Monitoring Center',
  user: 'User',
  fieldOperative: 'Field Operative',
};

export const ADMIN_ROLE = {
  SPG_SUPER_ADMIN: 'spgSuperAdmin',
  SPG_SUPPORT_STAFF: 'spgSupportStaff',
  ENTITY_PRINCIPAL_ADMIN: 'entityPrincipalAdmin',
  ENTITY_SUPPORT_STAFF: 'entitySupportStaff',
  MONITORING_CENTRE: 'monitoringCentre',
};

export const EMERGENCY_STATUS = {
  SAFE: 0,
  ACTIVE: 1,
  SILENT: 2,
  CANCLED: 3,
  ENDED: 4,
};
export const USER_ROLE = {
  SPG_USER: 'user',
  SPG_FO: 'fieldOperative',
};

export const PERMISSION_TYPES = {
  FETCH: 'fetch',
  CREATE: 'create',
  UPDATE: 'update',
  DELETE: 'delete',
};

export const PERMISSION_ON = {
  ENTITY: 'entity',
  CLIENT: 'client',
  ADMINS: 'admins',
  MONITORING_CENTRE: 'monitoringcenter',
  FIELD_OPERATIVE: 'fieldoperative',
  SUPPORT_STAFF: 'supportstaff',
  EMERGENCY: 'emergency',
  GLOBAL_REPORTS: 'globalreports'
};

export const USER_STATUS = {
  INACTIVE: 0,
  ACTIVE: 1,
  DISABLED: 2,
  PENDING: 3,
};

export const LANGUAGE_CONSTANT = {
  VALID_PHONE_NUMBER: 'Must be a valid phone number',
};
