import { Link as RouterLink } from 'react-router-dom';

// material-ui
import { Link } from '@mui/material';

// project imports
import { DASHBOARD_PATH } from 'config';
import Logo from 'ui-component/Logo';

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = ({ dark, width, height }) => (
  <Link component={RouterLink} to={DASHBOARD_PATH}>
    <Logo dark={dark} height={height} width={width} />
  </Link>
);

export default LogoSection;
