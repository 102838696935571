import PropTypes from 'prop-types';
import { memo, useMemo } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
  Box,
  Divider,
  Drawer,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';

// project imports
import MenuList from './MenuList';
import LogoSection from '../LogoSection';
import { openDrawer } from 'store/slices/menu';
import { useDispatch, useSelector } from 'store';
import { drawerWidth } from 'store/constant';
import Chip from 'ui-component/extended/Chip';
// ==============================|| SIDEBAR DRAWER ||============================== //

const Sidebar = ({ window }) => {
  const theme = useTheme();
  const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));
  const matchUpLg = useMediaQuery(theme.breakpoints.up('lg'));

  const dispatch = useDispatch();
  const { drawerOpen } = useSelector((state) => state.menu);

  const logo = useMemo(
    () => (
      <Box sx={{ display: { xs: 'block', lg: 'none' } }}>
        <Box sx={{ display: 'flex', p: 2, ml: 3 }}>
          <LogoSection dark />
          <Typography
            variant='h3'
            sx={{
              marginLeft: 2,
              marginY: 'auto',
            }}
          >
            SPG
          </Typography>
        </Box>
      </Box>
    ),
    []
  );

  const drawer = useMemo(
    () => (
      <>
        <PerfectScrollbar
          component='div'
          style={{
            height: !matchUpMd ? 'calc(100vh - 56px)' : 'calc(100vh - 106px)',
            paddingLeft: '16px',
            paddingRight: '16px',
          }}
        >
          {/* Sidebar Menus */}
          <MenuList />
        </PerfectScrollbar>
        <Divider sx={{ mt: 0.25, mb: 1.25, marginX: 1 }} />
        <Stack direction='row' justifyContent='center' sx={{ mb: 2 }}>
          <Chip
            label={process.env.REACT_APP_VERSION}
            disabled
            chipcolor='primary'
            size='small'
            sx={{ cursor: 'pointer' }}
          />
        </Stack>
      </>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [matchUpMd]
  );

  const container =
    window !== undefined ? () => window.document.body : undefined;

  return (
    <Box
      component='nav'
      sx={{ flexShrink: { md: 0 }, width: matchUpLg ? drawerWidth : 'auto' }}
      aria-label='mailbox folders'
    >
      <Drawer
        container={container}
        variant={matchUpLg ? 'persistent' : 'temporary'}
        anchor='left'
        open={drawerOpen}
        onClose={() => dispatch(openDrawer(!drawerOpen))}
        sx={{
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            background: theme.palette.background.default,
            color: theme.palette.text.primary,
            borderRight: 'none',
            [theme.breakpoints.up('lg')]: {
              top: '106px',
              maxHeight: 'calc(100vh - 106px)',
            },
          },
        }}
        ModalProps={{ keepMounted: true }}
        color='inherit'
      >
        {drawerOpen && logo}
        {drawerOpen && drawer}
      </Drawer>
    </Box>
  );
};

Sidebar.propTypes = {
  window: PropTypes.object,
};

export default memo(Sidebar);
