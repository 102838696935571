import { useEffect, useRef, useState, useCallback } from 'react';
// import { Link } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
  Avatar,
  Badge,
  Box,
  Button,
  CardActions,
  Chip,
  ClickAwayListener,
  Divider,
  Grid,
  Paper,
  Popper,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import Transitions from 'ui-component/extended/Transitions';
import NotificationList from './NotificationList';
import { API, fetchRecord, postRecord } from 'services/AxiosAdmin';
// assets
import { IconBell } from '@tabler/icons';
import { getFirebaseToken, onMessageListener } from 'firebase';
import useAuth from 'hooks/useAuth';
import initPubNub from 'services/PubNubService';
import { PubNubProvider, usePubNub } from 'pubnub-react';

// ==============================|| NOTIFICATION ||============================== //

const NotificationSection = () => {
  const theme = useTheme();
  const matchesXs = useMediaQuery(theme.breakpoints.down('md'));

  const [open, setOpen] = useState(false);
  const [unreadCount, setUnreadCount] = useState(0);
  const [notifications, setNotifications] = useState([]);
  const [query, setQuery] = useState();

  /**
   * Push Notifications permession
   * */

  const [tokenFound, setTokenFound] = useState();

  useEffect(() => {
    getFirebaseToken(setTokenFound);
  }, []);

  useEffect(() => {
    //Update token to user
    if (tokenFound) {
      API.patch(
        `${process.env.REACT_APP_API_URL}/admin/device-info/push-token`,
        {
          pushToken: tokenFound,
        }
      );
    }
  }, [tokenFound]);

  onMessageListener()
    .then((payload) => {
      if (payload) {
        getUnreadCount();
      }
    })
    .catch((err) => console.log('failed: ', err));

  //use Pubnub
  const Pubnub = usePubNub();

  const handleSignal = async (event) => {
    if (event.hasOwnProperty('message') && event?.message === 'reload') {
      getUnreadCount();
    }
  };

  const pubnubListener = {
    signal: handleSignal,
  };

  useEffect(() => {
    Pubnub.subscribe({ channels: ['emergencies'] });
  }, [Pubnub]);

  useEffect(() => {
    Pubnub.addListener(pubnubListener);

    return () => {
      Pubnub.removeListener(pubnubListener);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Pubnub]);

  /**
   * anchorRef is used on different componets and specifying one type leads to other components throwing an error
   * */
  const anchorRef = useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => {
      setQuery();
      setNotifications([]);
      return !prevOpen;
    });
    getUnreadCount();
    getNotifications();
  };

  const handleMarkAllasRead = async () => {
    let response = await postRecord('/admin/notifications/mark-as-read');
    if (response.status === 200) {
      getUnreadCount();
      // setNotifications([]);
      getNotifications();
    }
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
    setNotifications([]);
    setQuery();
  };

  const handleViewMore = () => {
    if (query?.hasNextPage) {
      getNotifications(query?.nextPage, query?.limit);
    }
  };

  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  const getUnreadCount = useCallback(async () => {
    let response;
    if (window.sessionStorage.getItem('entityName')) {
      response = await fetchRecord('/admin/notifications/count', {
        entity: window.sessionStorage.getItem('entityId'),
      });
    } else {
      response = await fetchRecord('/admin/notifications/count');
    }

    if (response.status === 200) {
      setUnreadCount(response?.data?.data?.count);
    }
  }, []);

  const getNotifications = useCallback(async (page = 1, limit = 10) => {
    let response;
    if (window.sessionStorage.getItem('entityName')) {
      response = await fetchRecord('/admin/notifications', {
        limit,
        page,
        entity: window.sessionStorage.getItem('entityId'),
      });
    } else {
      response = await fetchRecord('/admin/notifications', { limit, page });
    }

    if (response.status === 200) {
      if (page === 1) {
        setNotifications(response?.data?.data?.docs);
      } else {
        setNotifications((prevData) =>
          prevData.concat(response?.data?.data?.docs)
        );
      }

      delete response?.data?.data?.docs;
      setQuery(response?.data?.data);
    }
  }, []);

  useEffect(() => {
    getUnreadCount();
  }, [getUnreadCount]);

  return (
    <>
      <Box
        sx={{
          // ml: 2,
          mr: 3,
          [theme.breakpoints.down('md')]: {
            mr: 2,
          },
          '&:hover': {
            boxShadow: 4,
            borderRadius: 2,
          },
        }}
      >
        <Avatar
          variant='rounded'
          sx={{
            ...theme.typography.commonAvatar,
            ...theme.typography.mediumAvatar,
            padding: 0,
            width: '64px',
            height: '64px',
            transition: 'all .2s ease-in-out',
            background:
              theme.palette.mode === 'dark'
                ? theme.palette.dark.main
                : theme.palette.secondary.light,
            color:
              theme.palette.mode === 'dark'
                ? theme.palette.warning.dark
                : theme.palette.grey[900],
            '&[aria-controls="menu-list-grow"],&:hover': {
              background:
                theme.palette.mode === 'dark'
                  ? theme.palette.warning.dark
                  : theme.palette.paper,
              color:
                theme.palette.mode === 'dark'
                  ? theme.palette.grey[800]
                  : theme.palette.grey[900],
            },
          }}
          ref={anchorRef}
          aria-controls={open ? 'menu-list-grow' : undefined}
          aria-haspopup='true'
          onClick={handleToggle}
          color='inherit'
        >
          <Badge badgeContent={unreadCount} color='secondary'>
            <IconBell stroke={1.5} size='1.3rem' />
          </Badge>
        </Avatar>
      </Box>

      <Popper
        placement={matchesXs ? 'bottom' : 'bottom-end'}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [matchesXs ? 5 : 0, 20],
              },
            },
          ],
        }}
      >
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={handleClose}>
            <Transitions
              position={matchesXs ? 'top' : 'top-right'}
              in={open}
              {...TransitionProps}
            >
              <Paper>
                {open && (
                  <MainCard
                    border={false}
                    elevation={16}
                    content={false}
                    boxShadow
                    shadow={theme.shadows[16]}
                  >
                    <Grid container direction='column' spacing={2}>
                      <Grid item xs={12}>
                        <Grid
                          container
                          alignItems='center'
                          justifyContent='space-between'
                          sx={{ pt: 2, px: 2 }}
                        >
                          <Grid item>
                            <Stack direction='row' spacing={2}>
                              <Typography variant='subtitle1'>
                                All Notification
                              </Typography>
                              <Chip
                                size='small'
                                label={unreadCount.toString()}
                                sx={{
                                  color: theme.palette.background.default,
                                  bgcolor: theme.palette.warning.dark,
                                }}
                              />
                            </Stack>
                          </Grid>
                          <Grid item>
                            <Typography
                              sx={{ cursor: 'pointer' }}
                              // component={Link}
                              // to='#'
                              variant='subtitle2'
                              color='secondary'
                              onClick={handleMarkAllasRead}
                            >
                              Mark as all read
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <PerfectScrollbar
                          style={{
                            height: '100%',
                            maxHeight: 'calc(100vh - 205px)',
                            overflowX: 'hidden',
                          }}
                        >
                          <Grid container direction='column' spacing={2}>
                            <Grid item xs={12} p={0}>
                              <Divider sx={{ my: 0 }} />
                            </Grid>
                          </Grid>
                          <NotificationList
                            notifications={notifications}
                            getNotifications={getNotifications}
                            getUnreadCount={getUnreadCount}
                            setNotifications={setNotifications}
                          />
                        </PerfectScrollbar>
                      </Grid>
                    </Grid>
                    <Divider />
                    {query?.hasNextPage && (
                      <CardActions sx={{ p: 1.25, justifyContent: 'center' }}>
                        <Button
                          size='small'
                          color='secondary'
                          disableElevation
                          onClick={handleViewMore}
                        >
                          View More
                        </Button>
                      </CardActions>
                    )}
                  </MainCard>
                )}
              </Paper>
            </Transitions>
          </ClickAwayListener>
        )}
      </Popper>
    </>
  );
};

const PubNubNotificationSection = () => {
  const { user } = useAuth();

  return (
    <PubNubProvider client={initPubNub(user?.deviceId)}>
      <NotificationSection />
    </PubNubProvider>
  );
};

export default PubNubNotificationSection;
