const AWS = require('aws-sdk');

const initS3Client = (key) => {
  const config = {
    region: process.env.REACT_APP_AWS_REGIONS,
    albumBucketName: key,
    bucketRegion: process.env.REACT_APP_AWS_REGIONS,
    IdentityPoolId: process.env.REACT_APP_AWS_COGNITO_POOL_ID,
    bucket: process.env.REACT_APP_AWS_BUCKET,
  };

  AWS.config.update({
    region: config.bucketRegion,
    credentials: new AWS.CognitoIdentityCredentials({
      IdentityPoolId: config.IdentityPoolId,
      region: config.region,
    }),
  });

  return new AWS.S3({
    apiVersion: '2006-03-01',
    params: { Bucket: config.albumBucketName },
  });
};

export const uploadToS3 = async (file, key) => {
  const bucketName = process.env.REACT_APP_AWS_BUCKET;
  const s3 = initS3Client(key);
  try {
    const uploadParams = {
      Bucket: bucketName,
      Key: `${key}/${file.name}`,
      Body: file,
      ACL: 'private',
      ContentType: file.type,
    };

    const uploadResult = await s3.upload(uploadParams).promise();
    return uploadResult;
  } catch (error) {
    throw error;
  }
};

export const getPresignedUrl = async (key, expiresIn = 496060) => {
  if (!key) return;
  const bucketName = process.env.REACT_APP_AWS_BUCKET;
  const s3 = initS3Client();

  try {
    const params = {
      Bucket: bucketName,
      Key: key,
      Expires: expiresIn,
    };

    const url = await s3.getSignedUrlPromise('getObject', params);
    return url;
  } catch (error) {
    throw error;
  }
};

export const deleteKey = async (key) => {
  const bucketName = process.env.REACT_APP_AWS_BUCKET;
  const s3 = initS3Client();
  try {
    const params = {
      Bucket: bucketName,
      Key: key,
    };
    return s3.deleteObject(params).promise();
  } catch (error) {
    return error;
  }
};
