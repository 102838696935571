import React from 'react';
import { Avatar } from '@mui/material';
import { encodeAwsImageUrl } from 'utils/fileHelper';

const AvatarFirstLetterIcon = ({ key, name, srcImage }) => (
  <>
    <Avatar
      key={key}
      src={encodeAwsImageUrl(srcImage || '')}
      title={name}
      sx={{ background: '#bc7878', color: 'white' }}
    >
      {name ? name.charAt(0).toUpperCase() : ''}
    </Avatar>
  </>
);

export default AvatarFirstLetterIcon;
