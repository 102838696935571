import useAuth from 'hooks/useAuth';

const usePermission = (permissionOn = '', permissionType = '') => {
  const { user, roles } = useAuth();

  const validAdminRole = roles && roles.find((item) => item[user?.role]);

  const validPermission =
    validAdminRole && validAdminRole[user.role][permissionOn];

  if (validPermission) {
    if (validPermission[permissionType]) {
      return true;
    }
    return false;
  }

  return false;
};

export default usePermission;
