// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';

// ----------------------------------------------------------------------

const initialState = {
  error: null,
  chats: [],
  user: {},
  users: [],
};

const slice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },

    // GET USER
    getUserSuccess(state, action) {
      state.user = action.payload;
    },

    // GET USER CHATS
    getUserChatsSuccess(state, action) {
      state.chats = action.payload;
    },

    // GET USERS
    getUsersSuccess(state, action) {
      state.users = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getUser(userId) {
  return async () => {
    try {
      const response = await axios.get(`/admin/alert/${userId}`);
      dispatch(slice.actions.getUserSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getUserChats(chats) {
  return async () => {
    try {
      dispatch(slice.actions.getUserChatsSuccess(chats));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getUsers(chatType = 'individual', params) {
  return async () => {
    try {
      const response = await axios.get(`/admin/alert/${chatType}`, {
        params,
      });
      if (response.status === 200 || response.status === 201) {
        dispatch(
          slice.actions.getUsersSuccess(
            response.data.data.map((data) => ({
              ...data,
              unReadCount: 0,
            }))
          )
        );
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
