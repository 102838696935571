import { useEffect, useState } from 'react';

const useNetworkStatus = () => {
  const [isOnline, setIsOnline] = useState(true);

  useEffect(() => {
    const checkNetworkStatus = async () => {
      try {
        // Send a request to the provided URL
        const response = await fetch('https://pubsub.pubnub.com/time/0');
        
        setIsOnline(response.ok);
      } catch (error) {
        setIsOnline(false);
      }
    };

    // Initial check
    checkNetworkStatus();
    const intervalId = setInterval(checkNetworkStatus, 2000); // Check every 2 seconds

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return isOnline;
};

export default useNetworkStatus;
