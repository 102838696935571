import PubNub from 'pubnub';

const initPubNub = (pubNubUuid = '6d5fcf30-b79c-40ef-8a81-d684823a950c') => {
  return new PubNub({
    publishKey: process.env.REACT_APP_PUBLISH_KEY,
    subscribeKey: process.env.REACT_APP_SUBSCRIBE_KEY,
    uuid: pubNubUuid,
    restore: true,
  });
};

export default initPubNub;
