import PropTypes from 'prop-types';
import { createContext, useCallback, useEffect, useReducer } from 'react';

// third-party
import jwtDecode from 'jwt-decode';
// import cookie from 'react-cookies'

// reducer - state management
import { LOGIN, LOGOUT, UPDATE_PROFILE } from 'store/actions';
import accountReducer from 'store/accountReducer';

// project imports
import Loader from 'ui-component/Loader';
import axios from 'utils/axios';

// constant
const initialState = {
  isLoggedIn: false,
  isInitialized: false,
  user: null,
  roles: null,
};

const verifyToken = (serviceToken) => {
  if (!serviceToken) {
    return false;
  }
  const decoded = jwtDecode(serviceToken);
  /**
   * Property 'exp' does not exist on type '<T = unknown>(token, options?: JwtDecodeOptions | undefined) => T'.
   */
  return decoded.exp > Date.now() / 1000;
};

const setSession = (serviceToken) => {
  if (serviceToken) {
    localStorage.setItem('serviceToken', serviceToken);
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
  } else {
    localStorage.removeItem('serviceToken');
    delete axios.defaults.headers.common.Authorization;
  }
};

// ==============================|| JWT CONTEXT & PROVIDER ||============================== //
const JWTContext = createContext(null);

export const JWTProvider = ({ children }) => {
  const [state, dispatch] = useReducer(accountReducer, initialState);

  const getInitialState = useCallback(async () => {
    try {
      const serviceToken = window.localStorage.getItem('serviceToken');
      if (serviceToken && verifyToken(serviceToken)) {
        setSession(serviceToken);
        const response = await axios.get('/admin/auth/me');
        const roles = await axios.get('/admin/auth/roles');
        const { data } = response;
        dispatch({
          type: LOGIN,
          payload: {
            isLoggedIn: true,
            user: data,
            roles: roles.data.data,
          },
        });
      } else {
        dispatch({
          type: LOGOUT,
        });
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: LOGOUT,
      });
    }
  }, []);

  useEffect(() => {
    getInitialState();
  }, [getInitialState]);

  const login = async (email, password) => {
    const response = await axios.post('/admin/auth/login', { email, password });
    const { token, data } = response.data.data;
    setSession(token.accessToken);
    const roles = await axios.get('/admin/auth/roles');

    dispatch({
      type: LOGIN,
      payload: {
        isLoggedIn: true,
        user: data,
        roles: roles.data.data,
      },
    });
  };

  const logout = () => {
    dispatch({ type: LOGOUT });
    setSession(null);
  };

  const forgotPassword = async (email) => {
    await axios.post('/admin/auth/forget-password', { email });
  };

  const resetPassword = async (password, token) => {
    setSession(token);
    let response = await axios.post('/admin/auth/reset-password', { password });
    if (response) {
      setSession(null);
    }
  };

  const updateProfile = (data) => {
    dispatch({
      type: UPDATE_PROFILE,
      payload: {
        isLoggedIn: true,
        user: data,
      },
    });
  };

  if (state.isInitialized !== undefined && !state.isInitialized) {
    return <Loader />;
  }

  return (
    <JWTContext.Provider
      value={{
        ...state,
        login,
        logout,
        resetPassword,
        updateProfile,
        forgotPassword,
      }}
    >
      {children}
    </JWTContext.Provider>
  );
};

JWTProvider.propTypes = {
  children: PropTypes.node,
};

export default JWTContext;
