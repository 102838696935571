// material-ui
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */

// ==============================|| LOGO SVG ||============================== //

const Logo = ({ dark, width = '69', height = '69' }) => {
  const theme = useTheme();
  return (
    /**
     * if you want to use image instead of svg uncomment following, and comment out <svg> element.
     *
     * <img src={theme.palette.mode === 'dark' ? logoDark : logo} alt="Berry" width="100" />
     *
     */
    <svg
      width={width}
      height={height}
      viewBox='0 0 79 79'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle
        cx='11.1268'
        cy='11.1268'
        r='11.1268'
        fill={dark ? theme.palette.common.black : theme.palette.common.white}
      />
      <circle
        cx='11.1268'
        cy='39.4999'
        r='11.1268'
        fill={dark ? theme.palette.common.black : theme.palette.common.white}
      />
      <circle
        cx='11.1268'
        cy='67.8732'
        r='11.1268'
        fill={dark ? theme.palette.common.black : theme.palette.common.white}
      />
      <circle
        cx='39.4998'
        cy='11.1268'
        r='11.1268'
        fill={dark ? theme.palette.common.black : theme.palette.common.white}
      />
      <circle
        cx='39.4998'
        cy='39.4999'
        r='11.1268'
        fill={dark ? theme.palette.common.black : theme.palette.common.white}
      />
      <circle
        cx='39.4998'
        cy='67.8732'
        r='11.1268'
        fill={dark ? theme.palette.common.black : theme.palette.common.white}
      />
      <circle
        cx='67.8729'
        cy='39.4999'
        r='11.1268'
        fill={dark ? theme.palette.common.black : theme.palette.common.white}
      />
    </svg>
  );
};

Logo.propTypes = {
  dark: PropTypes.bool,
};

Logo.defaultValues = {
  dark: false,
};

export default Logo;
